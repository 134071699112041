@import "../../../styles/base/variables";
.single-select-field {
  .required {
    color: $app-color-red;
  }
  
  ul {
    margin-bottom: 24px;
    padding: 0;
    li {
      list-style: none;

      &:nth-child(2) {
        border-bottom: 1px solid black;

        .field-input {
          position: relative;
          &:focus {
            background-color: $app-color-grey-5;
          }
        }
      }
    }
  }

  label {
    font-size: 14px;
    color: #000;
  }

  .field-input {
    width: 100%;
    background-color: transparent;
    border: none;
    color: #000;
    font-size: 18px;
    font-weight: 300;
    outline: none;
    margin-bottom: 3px;
  }
}

.single-select-field-error {
  ul {
    margin-bottom: 24px;
    padding: 0;
    li {
      list-style: none;

      &:nth-child(2) {
        border-bottom: 1px solid #c2112e;
      }
    }
  }

  label {
    font-size: 14px;
    color: #c2112e;
  }

  span {
    color: #c2112e;
  }

  .field-input {
    width: 100%;
    background-color: transparent;
    border: none;
    color: #c2112e;
    font-size: 18px;
    outline: none;
    margin-bottom: 7px;
    &:focus {
      background-color: $app-color-grey-5;
    }
  }
}

.single-select-field-disabled {
  ul {
    margin-bottom: 24px;
    padding: 0;
    border-bottom: 1px solid $app-color-grey4;

    li {
      list-style: none;
    }

    &:nth-child(2) {
    }
  }

  label {
    font-size: 10px;
    color: $app-color-grey;
  }

  .field-input {
    width: 100%;
    background-color: transparent;
    border: none;
    color: $app-color-grey4;
    font-size: 18px;
    outline: none;
  }
}
