@import "../../styles/base/variables";


.health_subtitle{
    font-weight: bold;
}

.regularRow{
    margin-bottom: 20px;
}

.red {
    color: $app-color-red;
}
.green {
    color: $app-color-green;
}