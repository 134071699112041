@import "../../../styles/base/variables";

.rbt-input {
  &.is-valid {
    border-bottom: 0.1rem solid $app-color-grey !important;
  }

  &.is-invalid {
    border-bottom: 0.1rem solid $app-color-red !important;
    color: $app-color-red;
  }

  border: none !important;
  border-bottom: 0.1rem solid $app-color-grey !important;
  border-radius: 0 !important;

  box-shadow: none !important;
  background-image: url(../../../assets/dashboard/arrow_down_black.png) !important;
  background-size: auto !important;
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);

  &:focus {
    background-image: url(../../../assets/dashboard/arrow_up_black.png) !important;
  }
}

.rbt {
  border: none;
  border-bottom: black;
}

.type-ahead-error {
  label {
    color: #c2112e;
  }

  span {
    color: #c2112e;
  }
}
