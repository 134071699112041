.mail {
  &__wrap {
    border: 1px solid rgba(136, 147, 164, 0.54);
    padding: 1rem 1rem !important;
  }
  &__title {
    font-weight: bold;
  }
  &__text-title {
    font-weight: bold;
    margin-top: 1rem;
  }

  &__default-recipients {
    border-bottom: 1px solid #edeff2;
  }

  &__fields {
    padding-top: 1rem;
    border-bottom: 1px solid #edeff2;

    input {
      width: 100%;
      border: none;

      &:active,
      &:focus {
        outline: none;
      }
    }
  }

  &__textarea {
    border-bottom: 1px solid #edeff2;
    textarea {
      margin-top: 0rem;
      margin-bottom: 2rem;
      width: 100%;
      border: none;
      min-height: 10rem;
      font-size: 1.4rem;

      &:active,
      &:focus {
        outline: none;
      }
    }
  }

  &__buttons {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    align-items: right;
    justify-content: right;
  }

  &__checkbox {
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
  }
}
