@import "../../../../../../styles/base/variables";

.red-cell {
    color: #ff2e17;
  }
  
  .yellow-cell {
    color: #ffd100;
  }
  
  .green-cell {
    color: #00b04f;
  }
  
  .MuiTableCell-root {
    padding: 0;
  }
  
  .MuiTooltip-tooltip {
    font-size: 1rem !important;
  }
