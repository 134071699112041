@import "../../../../styles/base/variables";

.link {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 18rem;
  color: $app-color-grey;
}

.pointer {
  cursor: pointer;
}

.trackItem {
    margin: 10px 10px 4px 10px;
    color: $app-color-grey;
    min-width: 75%;
}

.trackItemCopy {
  margin-top: 8px;
  color: $app-color-grey;
}
