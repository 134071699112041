@import "../../styles/base/variables";

.existentBrokerNav {
  color: red;
  font-size: 1.2rem;
  margin-bottom: 2.4rem !important;
  
  .button {
    background-color: $app-color-red4;
    color: #fff;
    font-size: 11px;
    display: inline-block;
    width: 110px;
    border-radius: 24px;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
    border: none;
  }
}
