@import "../../../../../styles/base/variables";

.overview {
  &__form-wrap {
    padding: 2rem;

    ul {
      margin-bottom: 5rem;
      // width: 30rem;
    }

    h1 {
      margin-bottom: 1rem;
    }

    .field-input {
      width: 100%;
    }
  }

  &__broker-details {
    margin-bottom: 5rem;

    &-input {
      input {
        border: none;
        border-bottom: 0.1rem solid $app-color-grey;
        border-radius: 0;

        &:focus {
          outline: none;
          box-shadow: none !important;
          // border: none;
        }

        &.form-control {
          font-size: 1.6rem;
          &.focus {
            border-bottom: 2px solid $app-color-grey !important;
          }
          &.is-valid {
            border-bottom: 0.1rem solid $app-color-grey;
          }
        }
      }

      .dropdown-menu {
        &.show {
          min-width: 240px;
          left: auto !important;
          right: auto !important;
          padding: 0;
          border: 0;
          box-shadow: 0 1px 5px 5px rgba(136, 147, 164, 0.19);

          .dropdown-item {
            padding: 15px 30px;
            border-bottom: 1px solid rgba(136, 147, 164, 0.19);
            font-size: 1.2rem;

            &:focus {
              outline: none;
            }

            &.active {
              background-color: $app-color-red4;
            }
          }
        }
      }
    }
  }

  &__broker-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 0.22px;
  }

  &__next-button {
    text-align: right;
  }

  &__proposal {
    &-info {
      label {
        color: $app-color-grey4;
      }

      input {
        border-color: $app-color-grey4;
        color: $app-color-grey4;
      }
    }

    &-wrap {
      display: none;

      &--active {
        display: block;
      }
    }
  }
}
