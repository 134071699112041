/**
 *  ProposalDocumentation.scss
 *  Disability Insurance Services
 *
 *  Created by Jeremy Moyers on Thu Apr  15 11:02:42 2019
 */
@import "../../../../styles/base/variables";

.proposal-documentation {
  &__wrap {
    display: flex;
    flex-direction: row;
  }

  &__form-wrap {
    padding: 2rem;

    ul {
      margin-bottom: 5rem;
    }

    h1 {
      margin-bottom: 1rem;
    }

    .field-input {
      width: 100%;
    }
  }

  &__container {
    max-width: 800px;
    width: 100%;
    margin: 0;
    margin-right: 3rem;
  }

  &__builder {
    box-shadow: $box-shadow2;
    background-color: $app-color-white;
    margin-bottom: 3rem;
  }

  &__aside-wrap {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
  }

  &__card-wrap {
    min-width: 30rem;
    background-color: $app-color-white;
    min-height: 10rem;
    margin-bottom: 2rem;
    color: $app-color-white;
    box-shadow: $box-shadow2;
  }

  &__card-title {
    background-color: $app-color-grey2;
    padding: 24px 20px;
  }

  &__head-id {
    min-height: 6.5rem;
    background-color: rgba(136, 147, 164, 0.18);
    border-top: 0.1rem solid rgba(136, 147, 164, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 4rem;

    .single-select-field {
      ul {
        margin-bottom: 1.3rem;
      }
    }
  }
}

.buttons {
  &__wrap {
    text-align: right;
  }

  &__display {
    display: inline-block;
  }

  &__mr-20 {
    margin-right: 2rem;
  }
}

.doc-tab {
  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
  
    h1 {
      margin: 0;
    }
  }

  &__title2 {
    margin-top: 7rem;
    margin-bottom: 2rem !important;
  }

  &__table-override {
    .table-container {
      .table-view__wrap {
        box-shadow: none;
        border: 1px solid #EDEFF2;
      }
    }
  }
}