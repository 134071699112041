@import "../../../styles/base/variables";

.text-field-light {
  ul {
    margin-bottom: 24px;
    padding: 0;
    li {
      list-style: none;
    }
  }

  label {
    font-size: 10px;
    color: #fff;
  }

  input {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px#ffffff;
    color: #fff;
    font-size: 18px;
    outline: none;

    &:focus {
      border-bottom: solid 2px#ffffff;
    }
  }
}

.text-field-dark {
  ul {
    margin-bottom: 24px;
    padding: 0;
    li {
      list-style: none;
    }
  }

  label {
    font-size: 14px;
    color: #000;
  }

  input {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px#000000;
    color: #000;
    font-size: 18px;
    outline: none;
    font-weight: 300;
    // padding-bottom: 12px;

    &:focus {
      border-bottom: solid 2px#000000;
    }
  }
}

.text-field-error {
  ul {
    margin-bottom: 24px;
    padding: 0;
    li {
      list-style: none;
    }
  }

  label {
    font-size: 10px;
    color: #c2112e;
  }

  span {
    color: #c2112e;
  }

  input {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px#C2112E;
    color: #c2112e;
    font-size: 18px;
    outline: none;
  }
}

.text-field-disabled {
  ul {
    margin-bottom: 24px;
    padding: 0;
    li {
      list-style: none;
    }
  }

  label {
    font-size: 14px;
    color: $app-color-grey2;
  }

  input {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px $app-color-grey2;
    color: $app-color-grey2;
    font-size: 18px;
    outline: none;
    // padding-bottom: 12px;
  }
}
