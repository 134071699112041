@import "../../styles/base/variables";

.side-nav {
  width: 190px;
  min-height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 0;
  box-shadow: 2px 14px 20px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $app-color-grey;
    min-height: 100vh;
    width: 100%;
    z-index: -1;
  }

  &__logo {
    background-image: url("../../assets/dashboard/DIS-Logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 150px;
    height: 60px;
    margin-top: 30px;
    margin-bottom: 60px;
  }

  &__add-button {
    background-color: $app-color-red4;
    color: $app-color-white;
    font-size: 14px;
    font-weight: 900;
    margin-bottom: 50px;
    padding: 17px 44px;
    border-radius: 28px;
    border: 0;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12);
    transition: 300ms box-shadow ease;
    outline: 0;
    position: relative;
    width: 14.7rem;
    max-width: 14.7rem;

    &:hover {
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12);
      outline: 0;
    }

    &:focus,
    &:active {
      outline: 0;
    }

    span {
      font-weight: 300;
      display: inline-block;
      font-size: 24px;
      position: absolute;
      left: 13px;
      top: 46%;
      transform: translateY(-50%);
    }
  }

  &__links {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;

    a {
      padding: 15px 30px 15px;
      font-size: 14px;
      color: $app-color-white;
      transition: 300ms background-color ease;

      &:hover,
      &.isActive {
        color: $app-color-white;
        text-decoration: none;
        background-color: $app-color-grey2;
      }

      &.isActive {
        font-weight: bold;
      }
    }
  }

  &__footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: rgba($color: $app-color-black, $alpha: 0.1);
    color: $app-color-white;
    padding: 26px 17px;

    button {
      background-color: transparent;
      border: none;
      font-size: 11px;
      font-weight: 900;
      letter-spacing: 0.3;

      &:after {
      }
    }

    .dropdown-menu {
      &.show {
        top: -0.7rem !important;
        left: 3.1rem !important;
        background-color: #545b62;
        button {
          color: $app-color-white !important;
          text-align: center;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}
