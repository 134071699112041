// colors

// color red
$app-color-red: #c2112e;
$app-color-red2: #a33223;
$app-color-red3: #8a2a1d;
$app-color-red4: #d85d0f;
$app-color-red5: #cd6321;

// color green
$app-color-green: #28cc42;
$app-color-green2: #21ac37;

// color blue
$app-color-blue: #225c8a;
$app-color-blue2: #1c4d74;
$app-color-blue3: #3a87fb;

// color grey
$app-color-grey: #47474f;
$app-color-grey2: #5c5c63;
$app-color-grey3: #555555;
$app-color-grey4: #8893a4;
$app-color-grey-5: #f5f6f7;
$app-color-grey6: #6e7278;
$app-color-grey7: #d8d8d8;
$app-color-grey8: rgba(34, 31, 31, 0.26);
$app-color-grey9: #f1f1f1;
$app-color-grey10: #4e4e4e;
$app-color-grey11: rgb(164, 164, 164);

// color yellow
$app-color-yellow: #d49e36;
$app-color-yellow2: #b3852d;

$app-color-white: #ffffff;
$app-color-black: #000000;

//  app shadows
$border-shadow: 0 2px 10px 10px rgba(136, 147, 164, 0.19);
$box-shadow2: 18px 3px 20px 10px rgba(136, 147, 164, 0.1);
$box-shadow3: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);

// Typography

$app-font-family: "Roboto";
$app-font-icons: "Material Icons";
