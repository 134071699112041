@import "../../styles/base/variables";

.launcher-modal {
  &__confirm-button {
    padding: 0.5rem 2rem !important;
    border-radius: 2rem !important;
    background-color: $app-color-grey !important;
    color: $app-color-white !important;
    font-size: 1.1rem !important;
    text-transform: capitalize !important;
  }

  &__cancel-button {
    padding: 0.5rem 2rem !important;
    border-radius: 2rem !important;
    background-color: $app-color-grey !important;
    color: $app-color-white !important;
    font-size: 1.1rem !important;
    text-transform: capitalize !important;
  }

  &__button-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
