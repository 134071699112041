@import "../../../../../styles/base/variables";

.case-design {
    &__wrap {
        padding: 0 5rem;
    }

    &__riders {
        margin-bottom: 1.6rem;
    }

    &__text-field-disabled {
        ul {
          margin-bottom: 2.4rem;
          padding: 0;
          li {
            list-style: none;
          }
        }
      
        span {
          font-size: 1rem;
          color: $app-color-grey2;
        }
      
        label {
          width: 100%;
          background-color: transparent;
          border: none;
          border-bottom: solid 1px $app-color-grey2;
          color: $app-color-grey2;
          font-size: 1.8rem;
          outline: none;
        }
      }

    &__toggle {
        display: flex;
        flex-direction: row;
        align-items: center; 
        height: 7.4rem;

        .toggle-field {
            label {
                margin-bottom: 0;
            }
        }
    }

    &__title {
        margin-bottom: 1.5rem;
    }
}
