@import "../../../../../styles/base/variables";

.classifications {
  &__form-wrap {
    padding: 2rem;
    overflow: auto;

    ul {
      margin-bottom: 5rem;
    }

    h1 {
      margin-bottom: 4rem;
    }

    .field-input {
      width: 100%;
    }
  }

  &__condition-wrap {
    margin-bottom: 4rem;
  }
}
