@import "../../styles/base/variables";

.accordion {
  padding: 0.5rem;
  color: black;
  max-height: 30rem;
  overflow: auto;
  

  .black {
    color: black !important;
  }

  .grey {
    color: $app-color-grey7 !important;
  }

  > .wrap {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    justify-content: space-between;
    padding: 0.5rem;

    > .preview {
      > .description {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 18rem;
      }

      > .title {
        font-weight: bold;
      }
    }

    > .chevron-wrap {
      width: 2rem;
    }
  }

  &__button-wrap {
    margin: 1rem 0;
  }
}

