@import "../../styles/base/variables";

.company-builder {
  &__wrap {
    max-width: 800px;
    background-color: white;
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-shadow: $border-shadow;
    align-items: center;
    justify-content: space-between;
    padding: 4rem;
    margin-right: 3rem;
  }

  &__form {
    display: flex;
    flex-direction: row;
  }

  &__spacer-40 {
    height: 4rem;
  }

  &__button-wrap {
    margin-top: 4rem;
    width: 90%;
    text-align: right;
  }

  &__title {
    margin-bottom: 3rem;
    margin-left: 2rem;
    color: white;
  }

  &__card-wrap {
    min-width: 30rem;
    background-color: $app-color-white;
    min-height: 10rem;
    margin-bottom: 5rem;
    box-shadow: $box-shadow2;

    .card-values {
      color: $app-color-grey2;
    }

    .card-row {
      padding: 1rem;
    }

    .card-separator {
      background-color: $app-color-grey8;
      min-height: 2px;
    }

    .card-totals {
      font-weight: bold;
    }
  }

  &__card-title {
    margin-top: 3rem;
    margin-bottom: 2rem;
    
    h2 {
      // font-size: 1.4rem;
      color: $app-color-grey10;
      text-transform: uppercase;
      font-weight: 900;
    }
  }

  &__affiliate-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
