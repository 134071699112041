@import "../../styles/base/variables";

.new-note {
    &__wrap {
        width: 100%;
    }

    &__title {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
        
        input {
            width: 100%;
            font-size: 1.2rem;
            font-weight: 500;
            padding: 0.5rem;

            &:focus {
                outline: none;
            }
        }
    }

    &__description {
        width: 100%;
        margin-bottom: 1rem;

        textarea {
            width: 100%;
            height: 29rem;
            font-size: 1.2rem;
            font-weight: 500;
            padding: 0.5rem;


            &:focus {
                outline: none;
            }
        }
    }
}