@import "../../styles/base/variables";

.button_grey {
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  background-color: #e0e0e0;
  
  font-size: 1.1rem;
  line-height: 1.1;
  font-weight: 500;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  align-items: center;
  user-select: none;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  
  padding: 4px 22px;
  font-size: 0.9375rem;
}

.button_grey:hover {
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  background-color: #d5d5d5;
  text-decoration: none;
}

.affiliate-builder {
  &__wrap {
    width: 78rem;
    background-color: white;
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-shadow: $border-shadow;
    align-items: center;
    justify-content: space-between;
    padding: 4rem;
  }

  &__form {
    display: flex;
    flex-direction: row;
  }

  &__spacer-40 {
    height: 40px;
  }

  &__button-wrap {
    margin-top: 4rem;
    width: 90%;
    text-align: right;
  }

  &__title {
    margin-bottom: 3rem;
    margin-left: 2rem;
  }

  &__card-wrap {
    min-width: 30rem;
    background-color: $app-color-white;
    min-height: 10rem;
    margin-bottom: 5rem;
    box-shadow: $box-shadow2;

    .card-values {
      color: $app-color-grey2;
    }

    .card-row {
      padding: 1rem;
    }

    .card-separator {
      background-color: $app-color-grey8;
      min-height: 2px;
    }

    .card-totals {
      font-weight: bold;
    }
  }

  &__card-title {
    margin-top: 3rem;
    margin-bottom: 2rem;
    h2 {
      // font-size: 1.4rem;
      color: $app-color-grey10;
      text-transform: uppercase;
      font-weight: 900;
    }
  }

  &__alt-button {
    margin-bottom: 4rem;
  }

  &__delete-btn {
    text-align: right;
  }

  &__table-override {
    .table-container {
      .table-view__wrap {
        box-shadow: none;
        border: 1px solid #edeff2;
      }
    }
  }
}
