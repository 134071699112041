@import "../../styles/base/variables";

.prompt-modal {
    &__save-button {
        padding: 0.5rem 1rem !important;
        border-radius: 2rem !important;
        background-color: $app-color-grey !important;
        color: $app-color-white !important;
        font-size: 1.1rem !important;
        text-transform: capitalize !important;
    }
}