@import "../../styles/base/variables";

.toast {
    &__close-button {
        color: $app-color-white !important;
        font-size: 1.4rem !important;

        &:hover {
            color: $app-color-red4 !important;
        }
    }
}