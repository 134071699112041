@import "./variables";

.heading1 {
    font-size: 3.6rem;
    font-weight: normal;
}

.heading3 {
    font-size: 14px;
    font-weight: 900;
}

.heading5 {
    font-size: 1.2rem;
    font-weight: normal;
    
    &--bold {
        font-weight: 900;
    }
}