@import "../../../../../styles/base/variables";

.client {
  &__form-wrap {
    padding: 2rem;

    ul {
      margin-bottom: 3rem;
      // width: 30rem;
    }

    h1 {
      margin-bottom: 1rem;
    }

    .field-input {
      width: 100%;
    }
  }

  &__drop-input {
    width: 100%;
    margin-top: -0.7rem;
    border: none;
    background-color: transparent;
    font-weight: 300;
    outline: none;
   

    input {
      font-size: 1.8rem;
      color:#000;
      font-weight: 300;

      &:focus {
        border-bottom: 0.2rem solid #47474f !important;
      }
    }
  }

  &__dob {
    input {
      font-size: 1.2rem;
    }
  }

  &__checkbox-group {
    margin-bottom: 3rem;
  }
}
