@import "../../styles/base/variables";

.forgot-pass {
    &__description {
        width: 326px;
        text-align: center;
        color: $app-color-white;
        margin-bottom: 60px;
    }
}

.forgot-pass {
    &__description {
        font-size: 1.6rem;
    }
}