@import "../../../styles/base/mixins";

.proposal {
  &__button-container {
    margin-bottom: 1.5rem;

    h1 {
      margin-bottom: 1rem;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: row;
  }

  &__container {
    //max-width: 800px;
    width: calc(100% - 330px);
    margin: 0;
    margin-right: 3rem;
  }

  &__aside-wrap {
    display: flex;
    flex-direction: column;
    width: 300px;
  }

  &__card-wrap {
    min-width: 30rem;
    background-color: $app-color-white;
    min-height: 10rem;
    margin-bottom: 2rem;
    color: $app-color-white;
    box-shadow: $box-shadow2;
  }

  &__card-title {
    background-color: $app-color-grey2;
    padding: 24px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__links {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: row;

      &:nth-child(1) {
      }
      &:nth-child(2) {
      }
    }
  }

  &__tabs {
    button {
      border: 0;
      box-shadow: none;
      background-color: transparent;
      outline: none;
      padding: 0;
      margin-right: 2rem;

      &:focus {
        outline: 0;
      }

      &.active {
        @include underline($border-height: 0.3rem);
        color: $app-color-black;
      }
    }
  }

  &__buttons {
    width: 30rem;

    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__notes {
    height: 21rem;
    overflow: auto;
  }

  &__disclaimer {
    textarea {
      width: 100%;
      height: 10rem;
      padding: 0.5rem;
      font-size: 1.2rem;
      outline: none;
      margin-bottom: 0rem;
      border: 0;
      resize: none;

      &:focus {
        outline: none;
      }
    }
  }
}

.propposalButton {
  min-width: 100px;
}

.disclaimerButtonContainer {
  margin-right: 14px;
}
