@import "../../styles/base/variables";

.product-builder {
  &__wrap {
    width: 1000px;
    background-color: white;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-shadow: $border-shadow;
    align-items: left;
    justify-content: space-between;
    padding: 40px 40px;

    h1 {
      margin-top: 3rem;
      margin-bottom: 2rem;
    }
  }

  &__spacer-40 {
    height: 40px;
  }

  &__button-wrap {
    width: 90%;
    text-align: right;
  }
}

.error {
  color: $app-color-red;
  font-size: 1.5em;
}