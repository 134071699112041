@import "../../styles/base/variables";

.producttype-filter {
  margin-right: 30px;

  &__label {
    font-size: 10px;
    margin: 0;
  }

  &__dropdown {
    min-width: 145px;

    &.dropdown {
      border-bottom: 1px solid #000;

      > button {
        width: 100%;
        text-align: right;
        background-color: transparent !important;
        text-align: left;
        color: black !important;
        border: 0;
        box-shadow: none !important;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &.show {
        > button {
          outline: none;
          color: black;

          &:focus,
          &:active {
            background-color: transparent;
            outline: none;
            box-shadow: none;
          }
        }
      }
    }

    .dropdown-menu {
      &.show {
        min-width: 240px;
        left: auto !important;
        right: 0;
        padding: 0;
        border: 0;
        box-shadow: 0 1px 5px 5px rgba(136, 147, 164, 0.19);

        .dropdown-item {
          padding: 15px 30px;
          border-bottom: 1px solid rgba(136, 147, 164, 0.19);
          font-size: 1.2rem;

          &:focus {
            outline: none;
          }

          &:active {
            background-color: $app-color-red4;
          }
        }
      }
    }

    .show > .btn-secondary.dropdown-toggle {
      background-color: rgba(255, 255, 255, 0);
    }
  }
}
