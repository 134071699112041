.analyzer {
  &__wrap {
    padding: 2rem;
    padding-right: 100px;
  }

  &__overflow {
    position: relative;

    &:after {
      display: inline-block;
      content: "";
      width: 9rem;
      height: 100%;
      background-image: linear-gradient(to left, #eaebef, transparent);
      opacity: 0.6;
      position: absolute;
      left: auto;
      right: 0;
      z-index: 1;
      top: 0;
    }
  }
}

.row {
  &__wrap {
    flex-wrap: nowrap !important;
    overflow-x: auto;
  }
}

.col {
  &__wrap {
    flex: 0 0 auto;
  }
}

.submitButtonsWrap {
  padding-right: 50px;
}
