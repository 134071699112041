.grey {
  color: $app-color-grey4;
  &--light {
    color: rgba($color: $app-color-grey4, $alpha: 0.36);
  }
}

.red-alert {
  color: $app-color-red2 !important;
  font-weight: bold;
}
