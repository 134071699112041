.table-view {
  &__content {
    max-width: 1090px;
    margin: 0 auto;
  }

  &__wrap {
    box-shadow: $border-shadow;
  }
}
