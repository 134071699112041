@import "../../styles/base/variables";

.login {
  background-image: url(../../assets/login/login-dis-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;

  &:before {
    background-color: rgba($color: #000000, $alpha: 0.65);
    z-index: -1;
    position: absolute;
    content: "";
    display: block; //for IE
    height: 100vh;
    width: 100%;
  }

  &__form-wrap {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 16px;
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 40px;
    }
  }

  &__form {
    .is-valid {
      border-radius: 2px;
      background-color: $app-color-green;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      font-weight: 900;
      padding: 10px 5px;
      width: 100%;
      max-width: 400px;
      word-break: break-all;
      margin-bottom: 50px;
    }

    .not-valid {
      border-radius: 2px;
      background-color: $app-color-red;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 1.4rem;
      padding: 10px 5px;
      width: 100%;
      max-width: 326px;
      word-break: break-all;
      margin-bottom: 50px;
    }

    button {
      margin-top: 49px;
      background-color: $app-color-red4;
      color: #fff;
      font-size: 11px;
      width: 320px;
      height: 48px;
      border-radius: 24px;
      margin-bottom: 31px;
      border: none;
    }
  }

  &__forgot-pwd {
    a {
      font-size: 11px;
      color: $app-color-red4;
    }
  }
}
