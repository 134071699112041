@import "../../styles/base/variables";

.linkItem{
    margin: 4px 10px 4px 10px;
    color: $app-color-grey;
}

.affiliate-quotes__card-title {
    background-color: $app-color-grey2;
    padding: 24px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
}