/**
 *  StatusBadge.tsx
 *  Disability Insurance Services
 *
 *  Created by Jeremy Moyers on Thu Apr  11 14:20:42 2019
 *  Copyright Seamgen, LLC. All rights reserved.
 */
@import "../../styles/base/variables";

.status-badge {
  padding: 8px 10px 8px 30px !important;
  position: relative;
  border-radius: 12px !important;
  width: 11rem;
  max-width: 11rem;
  text-align: left !important;
  font-size: 1.1rem !important;
  height: 2.5rem;

  &:before {
    content: "";
    font-family: 'Material Icons';
    font-size: 12px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--qa {
    background-color: $app-color-blue !important;

    &:before {
      background-color: $app-color-blue2;
      content: '\e153';
      // background-image: url("../../assets/dashboard/qa.png");
    }
  }

  &--unassigned {
    background-color: $app-color-red2 !important;

    &:before {
      background-color: $app-color-red3;
      // content: '\ebb0'; // doesnt work :(
      background-image: url("../../assets/dashboard/add_disabled_white_18dp.png");
      background-size: 12px;
    }
  }

  &--single-class {
    background-color: $app-color-red2 !important;

    &:before {
      background-color: $app-color-red3;
      content: '\e876';
      // background-image: url("../../assets/dashboard/checkmark.png");
    }
  }

  &--assigned {
    background-color: $app-color-green !important;

    &:before {
      background-color: $app-color-green2;
      content: '\e7fd';
      // background-image: url("../../assets/dashboard/assigned.png");
    }
  }

  &--pending {
    background-color: $app-color-yellow !important;

    &:before {
      background-color: $app-color-yellow2;
      content: '\e002';
      // background-image: url("../../assets/dashboard/pending.png");
    }
  }

  &--sent {
    background-color: $app-color-grey6 !important;

    &:before {
      background-color: $app-color-grey3;
      content: '\e163';
      // background-image: url("../../assets/dashboard/sent.png");
    }
  }

  &--app-submitted {
    background-color: $app-color-grey4 !important;

    &:before {
      background-color: $app-color-grey6;
      content: '\e24d';
      // background-image: url("../../assets/dashboard/app-submitted.png");
      // background-size: 12px;
    }
  }

  &--in-force {
    background-color: $app-color-black !important;

    &:before {
      background-color: $app-color-black;
      content: '\e877';
      // background-image: url("../../assets/dashboard/in-force.png");
      // background-size: 12px;
    }
  }

  &--void {
    background-color: $app-color-grey7 !important;

    &:before {
      background-color: $app-color-grey11;
      content: '\e033';
      // background-image: url("../../assets/dashboard/void.png");
      // background-size: 12px;
    }
  }
}
