@import "../../styles/base/variables";

.dropzone {
  background-color: #edeff2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 10rem;
  font-size: 1.2rem;

  p {
    margin-bottom: 0;
  }

  &__select-wrap {
    .single-select-field {
      > ul {
        > li {
          &:nth-child(2) {
            width: 20rem;
          }
        }
      }
    }
  }

  &__upload-button {
    padding: 0.5rem 1rem !important;
    border-radius: 2rem !important;
    background-color: $app-color-grey !important;
    color: $app-color-white !important;
    font-size: 1.3rem !important;
    text-transform: capitalize !important;
  }

  &__cancel-button {
    color: $app-color-red4 !important;
    font-size: 1.4rem !important;
  }

  &__error {
    color: #c2112e;
  }
}

.fileNameColumn {
  margin-top: 30px;
}

.actionButtonColumn {
  margin-top: 30px;
}
